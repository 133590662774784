import React, { memo, useCallback, useMemo } from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { DropdownCore } from './DropdownCore';
import { DropdownItem } from './DropdownItem';
import { DropdownDnDProps } from './types';
import classes from './DropdownDnD.module.scss';

export const DropdownDnD = memo(({
  items,
  label: labelProps,
  classNameDropdownToggle,
  classNameDropdownMenu,
  className,
  onChange,
  theme = Theme.dark,
  onToggle,
  align,
}: DropdownDnDProps) => {
  const setChecked = useCallback((val: boolean, id: string) => (
    onChange?.(items.map((item) => (item.id === id ? { ...item, checked: val } : item)))
  ), [onChange, items]);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    onChange?.(update(items, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, items[dragIndex]],
      ],
    }));
  }, [items, onChange]);

  const checkedItems = useMemo(() => items.reduce((acc, { checked }) => acc + (+checked), 0), [items]);

  return (
    <DropdownCore
      className={className}
      label={labelProps}
      autoClose="outside"
      theme={theme}
      classNameDropdownMenu={cn(classes.menu, classNameDropdownMenu)}
      classNameDropdownToggle={cn(classes.toggle, classNameDropdownToggle)}
      onToggle={onToggle}
      align={align}
    >
      <DndProvider backend={HTML5Backend}>
        {items.map(({ label, checked, id }, idx) => (
          <DropdownItem
            key={id}
            {...{
              label, checked, id, setChecked, idx, moveItem, theme, canCheck: checkedItems > 1,
            }}
          />
        ))}
      </DndProvider>
    </DropdownCore>
  );
});
