import { useState, useMemo } from 'react';
import dynamic from 'next/dynamic';

import { Box } from 'uikit/Box';
import { Divider } from 'uikit/Divider';
import { Icon } from 'uikit/Icon';
import { Radio } from 'uikit/Radio';
import { Storage, Provider } from 'components/Account/types';
import {
  getAccountData, textLine, title, notificationLine, settings,
} from './helpers';
import classes from './DemoAccount.module.scss';
import { DemoAccountProps } from './types';
import { DataBlock } from './DataBlock';

const Modal = dynamic(() => import('../../../uikit/Modal/Modal'), { ssr: false });

export const DemoAccount = ({
  show, onClose, provider, user,
}: DemoAccountProps) => {
  const [selected] = useState([Storage.SP_CLOUD as string, Provider.SP_PROVIDER as string]);
  const { id, email } = user || {};
  const accountData = useMemo(() => getAccountData(id, email, provider), [id, email, provider]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      dialogClassName={classes.dialog}
      bodyClassName={classes.body}
    >
      <Box direction="column">
        <DataBlock {...{ title, accountData }} />
        <Divider />
        <p className={classes.textLine}>{textLine}</p>
        <Box direction="column" className={classes.blockSettings}>
          {
            settings.map(({ id, title, data }) => (
              <Box className={classes.settingsSection} key={id} direction="column">
                <h3 className={classes.title}>{title}</h3>
                <Box className={classes.radioGroup} direction="column">
                  {data.map(({ value, label }, idx: number) => (
                    <Radio
                      key={value}
                      value={value}
                      checked={selected.includes(value)}
                      disabled={idx === 1}
                      label={label}
                      classNameContainer={classes.container}
                      name={id}
                    />
                  ))}
                </Box>
                <Box className={classes.notification} alignItems="center">
                  <Icon name="info" width={11} className={classes.icon} />
                  <span className={classes.notificationText}>{notificationLine}</span>
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
    </Modal>
  );
};
