'use client';

import DropdownCore from 'react-bootstrap/Dropdown';
import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { DropdownToggle } from './DropdownToggle';
import { DropdownMenu } from './DropdownMenu';
import { DropdownProps } from './types';
import classes from './Dropdown.module.scss';

export const Dropdown = memo(({
  items = [],
  active,
  classNameDropdownToggle,
  classNameDropdownMenu,
  className,
  renderToggleContent,
  isShowActive = true,
  onSelect,
  theme = Theme.dark,
  dataTestId = 'dropdown',
  showArrow,
  align,
  id = 'dropdown-basic',
}: DropdownProps) => {
  const activeItemInItems = useMemo(() => items.find(({ value }) => value === active), [items, active]);
  const filteredList = useMemo(
    () => (isShowActive ? items : items.filter(({ value }) => value !== active)),
    [items, isShowActive, active],
  );
  const onSelectValue = useCallback((eventKey: string | null) => {
    if (eventKey) {
      onSelect?.(filteredList[+eventKey - 1]?.value);
    }
  }, [onSelect, filteredList]);
  return (
    <DropdownCore onSelect={onSelectValue} className={cn(className, classes[theme])} data-testid={dataTestId}>
      <DropdownCore.Toggle
        id={id}
        className={cn(classes.dropdown, classNameDropdownToggle)}
        as={DropdownToggle}
        showArrow={showArrow}
      >
        {typeof renderToggleContent === 'function' ? renderToggleContent(activeItemInItems) : activeItemInItems?.label}
      </DropdownCore.Toggle>
      <DropdownCore.Menu
        className={cn(classes.dropdownMenu, classNameDropdownMenu)}
        as={DropdownMenu}
        align={align}
      >
        {filteredList.map(({
          label, href, external, disabled,
        }, idx) => {
          return (
            <DropdownCore.Item
              disabled={disabled}
              eventKey={(idx + 1).toString()}
              key={`${label}-${idx}`}
              className={classes.menuItem}
              as="div"
              data-testid={`${dataTestId}-item-${idx}`}
            >
              <Box alignItems="center">
                {href && !disabled ? (
                  <Link
                    href={href}
                    className={classes.menuitemtext}
                    target={external ? '_blank' : undefined}
                  >
                    {label}
                  </Link>
                ) : <span className={cn(classes.menuitemtext, { [classes.disabled]: disabled })}>{label}</span>}
              </Box>
            </DropdownCore.Item>
          );
        })}
      </DropdownCore.Menu>
    </DropdownCore>
  );
});

export default Dropdown;
