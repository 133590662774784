import { Theme } from 'uikit/common/types';

export interface CheckConfidentialityModalProps {
  theme: Theme;
}

export enum MessageTypes {
  height = 'height',
  copy = 'copy',
}
