import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Icon } from 'uikit/Icon';
import classes from './DropdownToggle.module.scss';
import { DropdownToggleProps } from './types';

export const DropdownToggle = forwardRef<HTMLDivElement, DropdownToggleProps>(({
  children, className, onClick, showArrow = true,
}, ref) => (
  <div
    className={cn(classes.btn, className)}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick?.(e);
    }}
    onKeyDown={() => {}}
    data-toggle="dropdown"
    role="button"
    tabIndex={0}
  >
    {children}
    {showArrow && <Icon name="triangle" width={8} className={classes.icon} />}
  </div>
));
