import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Box } from 'uikit/Box';
import { DataBlock } from 'components/Account/DemoAccount/DataBlock';
import { Storage } from './Storage';
import {
  getAccountData, title,
} from './helpers';
import classes from './Web3Account.module.scss';
import { Web3AccountProps } from './types';

const Modal = dynamic(() => import('../../../uikit/Modal/Modal'), { ssr: false });

export const Web3Account = ({
  show, onClose, user,
}: Web3AccountProps) => {
  const { id, address } = user || {};

  const accountData = useMemo(() => getAccountData(id, address), [id, address]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      dialogClassName={classes.dialog}
      bodyClassName={classes.body}
    >
      <Box direction="column">
        <DataBlock {...{ title, accountData }} />
        <Storage />
      </Box>
    </Modal>
  );
};
