import { Providers as ProvidersOAuth2 } from 'lib/features/authOAuth2/types';
import { Providers as ProvidersSecretKeeper } from 'lib/features/secretKeeper/types';

export const title = 'Enter Marketplace';

export const subTitle = [
  'As Web3 User',
  'As Demo User',
];

export const line = [
  'Enter with a Polygon account for full functionality.',
  'Enter with an OAuth2 account for a free demo (with some limitations). ',
];

const basePath = '/icons/';

export const socialIcons = {
  metamask: {
    src: `${basePath}metamask.png`,
    alt: 'metamask',
    width: 23,
    height: 23,
    label: 'MetaMask',
    provider: ProvidersSecretKeeper.metamask,
  },
  github: {
    src: `${basePath}github.png`,
    alt: 'github',
    width: 14,
    height: 14,
    label: 'GitHub',
    provider: ProvidersOAuth2.github,
  },
  google: {
    src: `${basePath}google.png`,
    alt: 'google',
    width: 14,
    height: 14,
    label: 'Google',
    provider: ProvidersOAuth2.google,
  },
  huggingface: {
    src: `${basePath}huggingface.svg`,
    alt: 'huggingface',
    width: 12,
    height: 12,
    label: 'Hugging Face',
    provider: ProvidersOAuth2.huggingface,
  },
  facebook: {
    src: `${basePath}facebook.png`,
    alt: 'facebook',
    width: 18,
    height: 18,
    label: 'Facebook',
    provider: ProvidersOAuth2.facebook,
  },
  microsoft: {
    src: `${basePath}microsoft.png`,
    alt: 'microsoft',
    width: 12,
    height: 12,
    label: 'Microsoft',
    provider: ProvidersOAuth2.microsoft,
  },
};

export const btnsLine = [
  ProvidersOAuth2.google,
  ProvidersOAuth2.huggingface,
  ProvidersOAuth2.github,
  ProvidersOAuth2.microsoft,
  ProvidersOAuth2.facebook,
];
