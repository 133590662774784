'use client';

import { useCallback } from 'react';

import { useAuth } from 'hooks/auth/useAuth';
import { Box } from 'uikit/Box';
import { Modal } from 'uikit/Modal';
import { DividerWithText } from 'uikit/Divider/DividerWithText';
import { closeModal } from 'lib/features/modals';
import { isTypeOpenedModalsSelector } from 'lib/features/modals/selectors';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { Providers as ProvidersOAuth2 } from 'lib/features/authOAuth2/types';
import { Providers as ProvidersSecretKeeper } from 'lib/features/secretKeeper/types';
import { ModalType } from 'lib/features/modals/types';
import { SocialBtn } from './SocialBtn';
import {
  title, subTitle, line, socialIcons, btnsLine,
} from './helpers';
import classes from './Login.module.scss';

export const Login = () => {
  const { login, showAuthError } = useAuth();
  const isOpened = useAppSelector(isTypeOpenedModalsSelector(ModalType.auth));
  const dispath = useAppDispatch();

  const onClose = useCallback(() => {
    dispath(closeModal({ type: ModalType.auth }));
  }, [dispath]);

  const handleAuth = useCallback((provider: ProvidersOAuth2 | ProvidersSecretKeeper) => {
    return () => {
      onClose();
      login(provider).catch(showAuthError);
    };
  }, [onClose, login, showAuthError]);

  return (
    <Modal
      show={isOpened}
      onClose={onClose}
      dialogClassName={classes.dialog}
      bodyClassName={classes.body}
    >
      <Box direction="column" alignItems="center">
        <div className={classes.title}>{title}</div>
        <div className={classes.subTitle}>{subTitle[0]}</div>
        <div className={classes.line}>{line[0]}</div>
        <SocialBtn icon={socialIcons.metamask} onClick={handleAuth(ProvidersSecretKeeper.metamask)} />
        <DividerWithText text="Or" />
        <div className={classes.subTitle}>{subTitle[1]}</div>
        <div className={classes.line}>{line[1]}</div>
        <Box direction="column" className={classes.btns}>
          {btnsLine.map((item) => (
            <SocialBtn
              key={item}
              icon={socialIcons[item]}
              onClick={handleAuth(item)}
              disabled={![
                ProvidersOAuth2.google,
                ProvidersOAuth2.huggingface,
                ProvidersOAuth2.microsoft,
                ProvidersOAuth2.github,
              ].includes(item)}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
