import Link from 'next/link';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { DocInfoIconProps } from './types';
import classes from './DocInfoIcon.module.scss';

export const DocInfoIcon = ({ href, theme = Theme.dark, className }: DocInfoIconProps) => (
  <Link
    href={href}
    target="_blank"
    className={cn(classes.link, className)}
  >
    <Box className={cn(classes.content, classes[theme])} alignItems="center" justifyContent="center">
      ?
    </Box>
  </Link>
);
