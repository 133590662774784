import Image from 'next/image';
import { Button } from 'uikit/Buttons/Button';
import { SocialBtnProps } from './types';
import classes from './SocialBtn.module.scss';

export const SocialBtn = ({ icon, onClick, disabled }: SocialBtnProps) => {
  const {
    alt,
    height,
    width,
    label,
    src,
  } = icon;

  return (
    <Button variant="black" onClick={onClick} className={classes.btn} classNameLink={classes.link} disabled={disabled}>
      <Image
        {...{
          alt, height, width, label, src,
        }}
        className={classes.img}
      />
      <span className={classes.label}>{icon.label}</span>
    </Button>
  );
};
