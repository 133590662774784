import {
  memo, FC, useCallback, useMemo, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { SquareButton } from 'uikit/Buttons/SquareButton';
import { Icon } from 'uikit/Icon';
import { DropdownDnD } from 'uikit/Dropdown/DropdownDnD';
import { themeSelector } from 'lib/features/theme';
import { updateTableColumns } from 'lib/features/tableSettings';
import { columnsTableSelector } from 'lib/features/tableSettings/selectors';
import { Theme } from 'uikit/common/types';
import { getItems } from './helpers';
import { TableConfiguratorProps } from './types';

export const TableConfigurator: FC<TableConfiguratorProps> = memo(({
  columns, table, className, classNameDropdownToggle,
}) => {
  const theme = useAppSelector(themeSelector);
  const dispatch = useAppDispatch();
  const storeColumns = useAppSelector(columnsTableSelector(table));
  const items = useMemo(() => getItems(columns, storeColumns), [columns, storeColumns]);
  const onChange = useCallback((items) => {
    dispatch(updateTableColumns({ table, columns: items }));
  }, [dispatch, table]);
  const [active, setActive] = useState(false);
  const onToggle = useCallback((nextShow: boolean) => {
    setActive(nextShow);
  }, []);

  return (
    <DropdownDnD
      theme={theme}
      items={items}
      onChange={onChange}
      className={className}
      classNameDropdownToggle={classNameDropdownToggle}
      align="end"
      onToggle={onToggle}
      label={(
        <SquareButton
          variant={theme === Theme.dark ? 'transparent' : 'white'}
          size="large"
          theme={theme}
          active={active}
          data-testid="button-column-properties"
        >
          <Icon name="settings" width={16} />
        </SquareButton>
      )}
    />
  );
});

export default TableConfigurator;