import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { DividerProps } from './types';
import classes from './Divider.module.scss';

export const Divider = ({ className, theme = Theme.dark }: DividerProps) => {
  return (
    <div className={cn(classes.line, classes[theme], className)} />
  );
};
