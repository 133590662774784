import { memo, FC } from 'react';
import { Box } from 'uikit/Box';
import cn from 'classnames';
import classes from './ModalTitle.module.scss';
import { ModalTitleProps } from './types';

export const ModalTitle: FC<ModalTitleProps> = memo(({ title, className }) => {
  return (
    <Box justifyContent="center" alignItems="center" className={cn(classes.wrap, className)}>
      <h3 className={classes.title}>{title}</h3>
    </Box>
  );
});