'use client';

import { memo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import cn from 'classnames';
import { Account } from 'components/Account';
import { useAppSelector } from 'lib/hooks';
import { useAuth } from 'hooks/auth/useAuth';
import { themeSelector } from 'lib/features/theme';
import { CheckConfidentiality } from 'components/CheckConfidentiality';
import { Box } from 'uikit/Box';
// import { MenuMobile } from './MenuMobile';
import classes from './Header.module.scss';
import { Logo } from './Logo';

export const Header = memo(() => {
  const theme = useAppSelector(themeSelector);
  const { isUserConnected } = useAuth();
  const router = useRouter();

  const onClick = useCallback((e) => {
    e.preventDefault();
    router.push('/', undefined);
  }, [router]);

  return (
    <Box className={cn(classes.wrap, classes[theme])} Tag="header" data-testid="header">
      {/* <MenuMobile theme={theme} onClickLogo={onClick} /> */}
      <Box alignItems="center" justifyContent="space-between" className={classes.content}>
        <Logo {...{ theme, onClick }} />
        <Box alignItems="center">
          <Account />
          {isUserConnected && <CheckConfidentiality theme={theme} />}
        </Box>
      </Box>
    </Box>
  );
});

export default Header;