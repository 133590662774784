import { useCallback } from 'react';
import isEqual from 'lodash.isequal';
import { validateRTKResponse } from 'lib/features/helpers';
import { Storage, PostStorage } from 'hooks/storage/types';
import { useLazyStorage } from 'hooks/storage/useLazyStorage';
import { useLazyUserSettings } from 'hooks/user/useLazyUserSettings';
import { getReadS3Config, getWriteS3Config } from 'utils/S3/utils';
import { UserSettings } from 'hooks/user/useUserSettings/types';
import { S3 } from 'utils/S3/S3';

export interface CheckUserSettingsAndStorageProps {
  onProcess?: () => void;
  onFinish?: () => void;
}

export interface UseCheckUserSettingsAndStorageResult {
  storage: Storage;
  userSettings: UserSettings;
}

export interface UseCheckUserSettingsAndStorage {
  checkAndUpdateUserSettingsAndStorage: (
    props?: CheckUserSettingsAndStorageProps
  ) => Promise<UseCheckUserSettingsAndStorageResult>
  checkAndUpdateUserSettings: (props: UserSettings) => Promise<UserSettings>;
  setCentralizedStorageToUserSettings: () => Promise<UserSettings>;
  createAndSetDecentralizedStorageToUserSettings: (storage: PostStorage) => Promise<UserSettings>;
  checkStorage: (storage: PostStorage) => Promise<void>;
}

export const useCheckUserSettingsAndStorage = (): UseCheckUserSettingsAndStorage => {
  const { getUserSettings, createUserSettings, updateUserSettings } = useLazyUserSettings();
  const {
    createCentralizedStorage, getActiveStorage, getStorages, createStorage,
  } = useLazyStorage();

  const checkAndUpdateUserSettingsAndStorage = useCallback(async (props?: CheckUserSettingsAndStorageProps) => {
    const { onProcess, onFinish } = props || {};
    let isProcess = false;
    let storage = await getActiveStorage();
    if (!storage) {
      onProcess?.();
      isProcess = true;
      // create new centralized storage if not exists
      storage = validateRTKResponse(await createCentralizedStorage(null), { path: 'message', skipStatuses: [409] }).data;
      // if exists try to get centralized
      if (!storage) {
        storage = (validateRTKResponse(await getStorages(null))?.data || []).find(({ isCentralized }) => Boolean(isCentralized));
      }
    }
    if (!storage) throw new Error('Storage required');
    let userSettings = validateRTKResponse(await getUserSettings(null), { skipStatuses: [404] })?.data;
    if (!userSettings) {
      onProcess?.();
      isProcess = true;
      // create new user settings and save new centralized storage id, if not exists
      userSettings = validateRTKResponse(await createUserSettings({ activeStorageId: storage?.id }))?.data;
    }
    if (!userSettings) throw new Error('User settings required');
    if (isProcess) {
      onFinish?.();
    }
    return { userSettings, storage };
  }, [getActiveStorage, getUserSettings, createUserSettings, createCentralizedStorage, getStorages]);

  const checkAndUpdateUserSettings = useCallback(async (newUserSettings: UserSettings) => {
    let userSettings = validateRTKResponse(await getUserSettings(null), { skipStatuses: [404] })?.data;
    if (!userSettings) {
      userSettings = validateRTKResponse(await createUserSettings(newUserSettings))?.data;
    } else {
      userSettings = validateRTKResponse(await updateUserSettings(newUserSettings))?.data;
    }
    return userSettings as UserSettings;
  }, [getUserSettings, createUserSettings, updateUserSettings]);

  const checkStorage = useCallback(async (storage: PostStorage) => {
    const { s3Credentials } = storage;
    if (!s3Credentials) throw new Error('Credentials required');
    const fileName = 'sp-check';
    const Body = 'sp-check';
    await S3.checkListAccess({ s3: getReadS3Config(storage) });
    await S3.uploadFile({
      fileName,
      s3: getWriteS3Config(storage, { params: { Body } }),
    });
    await S3.downloadFile({
      fileName,
      s3: getReadS3Config(storage),
    });
    await S3.deleteFile({
      fileName,
      s3: getWriteS3Config(storage),
    });
  }, []);

  const createAndSetDecentralizedStorageToUserSettings = useCallback(async (storage: PostStorage) => {
    // check previous storage with same credentials
    const storages = validateRTKResponse<Storage[]>(await getStorages(null))?.data;
    let newStorage = (storages || [])
      .find(
        (oldStorage) => storage.storageType === oldStorage.storageType
          && oldStorage.prefix === storage.prefix
          && oldStorage.bucket === storage.bucket
          && isEqual(oldStorage.s3Credentials, storage.s3Credentials)
          && isEqual(oldStorage.storjCredentials, storage.storjCredentials),
      );
    if (!newStorage) {
      await checkStorage(storage);
      newStorage = validateRTKResponse(await createStorage(storage))?.data;
    }
    return checkAndUpdateUserSettings({ activeStorageId: newStorage?.id, decentralizedStorageId: newStorage?.id });
  }, [checkAndUpdateUserSettings, createStorage, checkStorage, getStorages]);

  const setCentralizedStorageToUserSettings = useCallback(async () => {
    const storages = validateRTKResponse(await getStorages(null))?.data;
    // todo requrest by filter isCentralized
    let centralized = (storages || []).find(({ isCentralized }) => isCentralized);
    if (!centralized) {
      centralized = validateRTKResponse(await createCentralizedStorage(null), { path: 'message' }).data;
    }
    if (!centralized) throw new Error('Storage required');
    return checkAndUpdateUserSettings({ activeStorageId: centralized?.id });
  }, [createCentralizedStorage, getStorages, checkAndUpdateUserSettings]);

  return {
    checkAndUpdateUserSettingsAndStorage,
    checkAndUpdateUserSettings,
    setCentralizedStorageToUserSettings,
    createAndSetDecentralizedStorageToUserSettings,
    checkStorage,
  };
};