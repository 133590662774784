import {
  memo, useMemo, useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';
import { Formik, FormikProps } from 'formik';
import { Box } from 'uikit/Box';
import { InputFormik } from 'uikit/Input/InputFormik';
import classes from '../../Web3Account.module.scss';
import {
  Fields, FormValues, StorjAccountRef, StorjAccountProps,
} from './types';
import {
  getValidationSchema, formatStoragePrefix,
} from './helpers';

export const StorjAccount = memo(forwardRef<StorjAccountRef, StorjAccountProps>(
  ({ initialValues = {}, onSubmit: onSubmitProps, disabled }, ref) => {
    const innerRef = useRef<FormikProps<FormValues>>(null);
    const [isValidating, setIsValidating] = useState(false);
    const validationSchema = useMemo(() => getValidationSchema(), []);

    const onSubmit = useCallback(async (values: FormValues) => {
      const formattedValues = {
        ...values,
        prefix: values?.prefix ? formatStoragePrefix(values.prefix.trim() as string) : values?.prefix,
      };
      onSubmitProps?.(formattedValues);
    }, [onSubmitProps]);

    const handleSubmit = useCallback(() => {
      setIsValidating(true);
      innerRef.current?.submitForm();
    }, []);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit,
    }));

    return (
      <Formik
        innerRef={innerRef}
        validateOnChange={isValidating}
        validateOnBlur={isValidating}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <Box direction="column">
          <span className={classes.titleLine}>Please enter your StorJ credentials for writing and reading data</span>
          <Box className={classes.inputLine} direction="column">
            <InputFormik
              label="Bucket"
              name={Fields.bucket}
              checkTouched={!isValidating}
              disabled={disabled}
            />
            <InputFormik
              label="Path"
              name={Fields.prefix}
              checkTouched={!isValidating}
              disabled={disabled}
              max={1024}
            />
            <InputFormik
              label="WriteAccessKeyId"
              name={Fields.writeAccessKeyId}
              checkTouched={!isValidating}
              type="password"
              disabled={disabled}
            />
            <InputFormik
              label="WriteSecretAccessKey"
              name={Fields.writeSecretAccessKey}
              checkTouched={!isValidating}
              type="password"
              disabled={disabled}
            />
            <InputFormik
              label="ReadAccessKeyId"
              name={Fields.readAccessKeyId}
              checkTouched={!isValidating}
              type="password"
              disabled={disabled}
            />
            <InputFormik
              label="ReadSecretAccessKey"
              name={Fields.readSecretAccessKey}
              checkTouched={!isValidating}
              type="password"
              disabled={disabled}
            />
          </Box>
        </Box>
      </Formik>
    );
  },
));
