import cn from 'classnames';

import { Icon } from 'uikit/Icon';
import classes from '../Checkbox.module.scss';
import { CheckboxCheckMarkProps } from './types';

export const CheckboxCheckMark = ({
  classNameCheckboxCheckmark, isInvalid, disabled, semi,
}: CheckboxCheckMarkProps) => {
  return (
    <i className={cn(
      classes.checkbox__checkmark,
      classNameCheckboxCheckmark,
      { [classes.checkbox__checkmark_invalid]: isInvalid },
      { [classes.checkbox__checkmark_disabled]: disabled },
      { [classes.checkbox__checkmark_semi]: semi },
    )}
    >
      <Icon name="check_small" width={12} />
    </i>
  );
};
