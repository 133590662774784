export enum Fields {
  bucket = 'bucket',
  writeAccessKeyId = 'writeAccessKeyId',
  writeSecretAccessKey = 'writeSecretAccessKey',
  readAccessKeyId = 'readAccessKeyId',
  readSecretAccessKey = 'readSecretAccessKey',
  prefix = 'prefix',
}

export interface FormValues {
  [Fields.bucket]?: string;
  [Fields.writeAccessKeyId]?: string;
  [Fields.writeSecretAccessKey]?: string;
  [Fields.readAccessKeyId]?: string;
  [Fields.readSecretAccessKey]?: string;
  [Fields.prefix]?: string;
}

export interface StorjAccountRef {
  submit: () => void;
}

export interface StorjAccountProps {
  onSubmit: (values: FormValues) => void;
  initialValues?: FormValues;
  disabled?: boolean;
}