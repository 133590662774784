import { forwardRef } from 'react';

import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { NotificationProps } from './types';
import classes from './Notification.module.scss';

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(({
  children, variant, className, classNameIcon, theme = Theme.dark, ...props
}, ref) => {
  return (
    <Box
      className={cn(
        classes.root,
        classes?.[variant],
        classes[theme],
        className,
      )}
      justifyContent="flex-start"
      alignItems="flex-start"
      ref={ref}
      {...props}
    >
      <Icon name={theme === Theme.dark ? 'info' : 'info_light'} width={11} className={cn(classes.icon, classNameIcon)} />
      {children}
    </Box>
  );
});
