import { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { Icon } from 'uikit/Icon';
import { Checkbox } from 'uikit/Checkbox';
import { ItemTypes } from './helpers';
import { DropdownItemProps, DragItem } from './types';
import classes from './DropdownItem.module.scss';

export const DropdownItem = ({
  label, checked, id, setChecked, idx, moveItem, theme = Theme.dark, canCheck = true,
}: DropdownItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.Item,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.idx;
      const hoverIndex = idx;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);

      item.idx = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.Item,
    item: () => {
      return { id, idx };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <Dropdown.Item
      eventKey={id}
      key={id}
      className={cn(classes[theme], classes.menuItem, { [classes.opacity]: !opacity })}
      as="div"
      ref={ref}
      data-handler-id={handlerId}
    >
      <Checkbox
        checked={checked}
        onChange={(val: boolean) => setChecked(val, id)}
        label={label}
        theme={theme}
        disabled={!canCheck && checked}
      />
      <Icon name="dnd" width={12} className={classes.icon} />
    </Dropdown.Item>
  );
};
