import { Box } from 'uikit/Box';
import { ModalTitle } from 'uikit/Modal/ModalTitle';
import classes from '../DemoAccount.module.scss';
import { DataBlockProps } from './types';

export const DataBlock = ({ title, accountData }: DataBlockProps) => (
  <>
    <ModalTitle title={title} />
    <Box direction="column" className={classes.blockData}>
      {accountData.map(({ label, data }) => (
        <Box className={classes.line} key={label}>
          <span className={classes.label}>{label}:</span>
          {data}
        </Box>
      ))}
    </Box>
  </>
);
