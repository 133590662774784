import getConfig from 'config';

import { sliceWithDot } from 'utils';
import { Item } from 'uikit/Dropdown/types';
import { Providers as ProvidersOAuth2, User as UserOAuth2 } from 'lib/features/authOAuth2/types';
import { Providers as ProvidersSecretKeeper } from 'lib/features/secretKeeper/types';
import { User } from 'hooks/user/types';
import { Events, ExtraData } from './AccountDropdown/types';

const { NEXT_PUBLIC_DOCS } = getConfig();

export const href = `${NEXT_PUBLIC_DOCS}/developers/marketplace`;

export const docLink = `${NEXT_PUBLIC_DOCS}/ai-marketplace/enter-marketplace`;

const extraData: { [key: string]: ExtraData } = {
  [ProvidersSecretKeeper.metamask]: {
    icon: '/icons/metamask.png', alt: 'metamask', width: 23, height: 23,
  },
  [ProvidersOAuth2.google]: {
    icon: '/icons/google.png', alt: 'google', width: 14, height: 14,
  },
  [ProvidersOAuth2.facebook]: {
    icon: '/icons/facebook.png', alt: 'facebook', width: 14, height: 14,
  },
  [ProvidersOAuth2.github]: {
    icon: '/icons/github.png', alt: 'github', width: 14, height: 14,
  },
  [ProvidersOAuth2.microsoft]: {
    icon: '/icons/microsoft.png', alt: 'microsoft', width: 14, height: 14,
  },
  [ProvidersOAuth2.huggingface]: {
    icon: '/icons/huggingface.svg', alt: 'huggingface', width: 14, height: 14,
  },
};

export const getItemByProvider = (user: User, provider: ProvidersOAuth2 | ProvidersSecretKeeper): Item | null => {
  if (!provider) return null;
  if (Object.values(ProvidersOAuth2).includes(provider as ProvidersOAuth2)) {
    return { label: sliceWithDot((user as UserOAuth2)?.email), value: provider, extraData: extraData[provider] };
  }
  if (Object.values(ProvidersSecretKeeper).includes(provider as ProvidersSecretKeeper)) {
    return { label: sliceWithDot(user?.address), value: provider, extraData: extraData[provider] };
  }
  return null;
};

export const getItems = (user?: User | null, provider?: ProvidersSecretKeeper | ProvidersOAuth2 | null): Item[] => {
  if (!provider || !user) return [];
  const items: Item[] = [];
  const itemByProvider = getItemByProvider(user, provider);
  if (itemByProvider) {
    items.push(itemByProvider);
  }
  return [
    ...items,
    { label: 'Account', value: Events.ACCOUNT },
    { label: 'Disconnect', value: Events.DISCONNECT },
  ];
};
