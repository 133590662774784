/* eslint-disable max-len */
import Image from 'next/image';
import { Storage, Provider } from 'components/Account/types';
import { socialIcons } from 'components/Login/helpers';
import { Box } from 'uikit/Box';
import classes from './DemoAccount.module.scss';

export const title = 'Demo Account';

export const getAccountData = (id?: number | string | null, email?: string | null, provider?: string | null) => {
  const image = socialIcons[provider as string];
  if (!id || !email || !provider || !image) return [];
  const {
    alt, height, label, src, width,
  } = image;

  return [
    {
      label: 'User ID',
      data: id,
    }, {
      label: 'Login',
      data: (
        <Box alignItems="center">
          <Image
            {...{
              alt, height, width, label, src,
            }}
            className={classes.imgIcon}
          />
          <span>{email}</span>
        </Box>
      ),
    },
  ];
};

export const textLine = 'As Super Protocol is a native Web3 cloud, is it recommended that you create a new Web3 account to access full capabilities of Super Protocol.';

export const settings = [
  {
    id: 'storage',
    title: 'Storage',
    data: [
      {
        value: Storage.SP_CLOUD,
        label: 'Super Protocol cloud',
      }, {
        value: Storage.STORJ_ACCOUNT,
        label: 'Your StorJ account',
      },
    ],
  },
  // }, {
  //   id: 'provider',
  //   title: 'Provider',
  //   data: [
  //     {
  //       value: Provider.SP_PROVIDER,
  //       label: 'Super Protocol provider',
  //     }, {
  //       value: Provider.OWN_PROVIDER,
  //       label: 'Your own provider',
  //     },
  //   ],
  // },
];

export const notificationLine = 'In OAuth2 demo mode only Super Protocol cloud is available';
